* {
    font-family: 'Open Sans', sans-serif;
}

.main {
    margin-top: 8rem;
    position: relative;
    min-height: 80vh;
    padding-bottom: 5.5rem; /* Footer height */
}
@media only screen and (max-width: 900px) {
    .main {
        margin-top: 4rem;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 80%;
    align-items: center;
    height: 5rem;            /* Footer height */
}

@media only screen and (max-width: 900px) {
    .footer-text {
        text-align: center;            /* Footer height */
    }
}

.footer-padding {
    display: block;
    padding-left: 10%;
    padding-right: 10%;
}

@media only screen and (min-width: 900px) {
    .footer-text {
        padding: 0 20px 20px 20px;
        text-align: right;
    }
}


body {
    background-color: #E2E2E2;
}
